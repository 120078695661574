import React, { useState } from 'react';
import host from "./constants";

const ManualTrading = ({apiKey,apiSecret}) => {
    const [symbol, setSymbol] = useState('');
    const [quantity, setQuantity] = useState('');
    const [leverage, setLeverage] = useState(0);
    const [orderType, setOrderType] = useState('Limit');
    const [side, setSide] = useState('Buy');
    const [price, setPrice] = useState('');
    const [stopLoss, setStopLoss] = useState('');
    const [category, setCategory] = useState('Linear');
    const [isOpen, setIsOpen] = useState(false); // State to manage panel visibility

    const handleSubmit = async (e) => {
        e.preventDefault();

        const orderData = {
            category: category.toLowerCase(),
            ...(orderType && { orderType: orderType }),
            ...(side && { side }),
            ...(quantity && { qty: quantity }),
            ...(symbol && { symbol }),
            ...(stopLoss && { stopLoss }),
            ...(leverage !== undefined && leverage !== 0 && { isLeverage: leverage }),
            ...(orderType !== 'Market' && { timeInForce: "PostOnly" }),
            orderFilter: "Order",
            ...(orderType !== 'Market' && price && { price })
        };

        console.log('Order Data:', orderData);

        try {
            const response = await fetch(`${host}/order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': apiKey,
                    'api-secret': apiSecret,
                  },
                body: JSON.stringify(orderData),
            });

            const result = await response.json();
            console.log('Response:', result);

            if (result.res.retMsg === "OK") {
                alert(`Order went through, order_id ${result.res.result.orderId}`);
            } else {
                alert(`Response:\n${result.res}`);
            }
        } catch (error) {
            alert('Error sending order:', error);
            console.error('Error sending order:', error);
        }
    };

    return (
        <div style={{ position: 'absolute', right: '20px', top: '100px' }}>
            {/* Button to toggle visibility */}
            <button 
                onClick={() => setIsOpen(!isOpen)} 
                style={{
                    position: 'absolute',
                    right: isOpen ? '240px' : '0',
                    top: '0',
                    width: '50px',
                    height: '30px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    transition: 'right 0.3s'
                }}
            >
                {isOpen ? 'close>' : '<open'}
            </button>

            {/* Trading panel */}
            <div style={{
                display: isOpen ? 'flex' : 'none',
                flexDirection: 'column',
                padding: '20px',
                borderRadius: '8px',
                width: '250px',
                fontFamily: 'Arial, sans-serif',
                backgroundColor: '#f0f0f0',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                transition: 'all 0.3s ease',
                marginRight: isOpen ? '0' : '-250px' // Hide off-screen when closed
            }}>
                <h2 style={{ margin: '0', fontSize: '1.5em', textAlign: 'center' }}>Manual Trading</h2>
                <form onSubmit={handleSubmit}>
                    {[
                        { label: 'Symbol', value: symbol, setValue: setSymbol, type: 'text', required: true },
                        { label: 'Qty-Contracts-4Linear', value: quantity, setValue: setQuantity, type: 'number', required: true },
                        { label: 'isLeverage 1/0', value: leverage, setValue: setLeverage, type: 'number', required: true },
                    ].map(({ label, value, setValue, type, required }, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ marginBottom: '5px' }}>
                                {label}:
                                <input
                                    type={type}
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    style={{
                                        padding: '8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        width: '80%',
                                    }}
                                    required={required}
                                />
                            </label>
                        </div>
                    ))}

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ marginBottom: '5px' }}>
                            Order Type:
                            <select
                                value={orderType}
                                onChange={(e) => {
                                    setOrderType(e.target.value);
                                    if (e.target.value === 'Market') {
                                        setPrice(''); // Reset price for Market orders
                                    }
                                }}
                                style={{
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    width: '90%',
                                }}
                            >
                                <option value="Limit">Limit</option>
                                <option value="Market">Market</option>
                            </select>
                        </label>
                    </div>

                    {[
                        {
                            label: 'Price',
                            value: price,
                            setValue: setPrice,
                            type: 'number',
                            required: orderType === 'Limit',
                            disabled: orderType === 'Market',
                        },
                        { label: 'Stop Loss', value: stopLoss, setValue: setStopLoss, type: 'number' }
                    ].map(({ label, value, setValue, type, required, disabled }, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ marginBottom: '5px' }}>
                                {label}:
                                <input
                                    type={type}
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    style={{
                                        padding: '8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ccc',
                                        width: '80%',
                                        backgroundColor: disabled ? '#e0e0e0' : '#fff',
                                    }}
                                    required={required}
                                    disabled={disabled}
                                />
                            </label>
                        </div>
                    ))}

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ marginBottom: '5px' }}>
                            Side:
                            <select
                                value={side}
                                onChange={(e) => setSide(e.target.value)}
                                style={{
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    width: '90%',
                                }}
                            >
                                <option value="Buy">Buy</option>
                                <option value="Sell">Sell</option>
                            </select>
                        </label>
                    </div>

                    {/* New Category Selection */}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ marginBottom: '5px' }}>
                            Category:
                            <select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                style={{
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    width: '90%',
                                }}
                            >
                                <option value="linear">Linear</option>
                                <option value="inverse">Inverse</option>
                                <option value="spot">Spot</option>
                            </select>
                        </label>
                    </div>

                    <button type="submit" style={{
                        marginTop: '15px',
                        padding: '10px',
                        backgroundColor: '#28a745',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '1em',
                    }}>
                        Place Order
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ManualTrading;
