import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import ManualTrading from './manualTrading';
import OpenPositionsTable from "./getAllPositions";
import WebhookTable from "./webhookTable";
import host from "./constants";
import { useAuth0 } from '@auth0/auth0-react';

const socket = io(host);

function App() {
  const [webhooks, setWebhooks] = useState([]);
  const [apiKey, setApiKey] = useState(localStorage.getItem('api_key') || '');
  const [apiSecret, setApiSecret] = useState(localStorage.getItem('api_secret') || '');
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showWebhooks, setShowWebhooks] = useState(true);
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();


  const handleConnect = async () => {
    localStorage.setItem('api_key', apiKey);
    localStorage.setItem('api_secret', apiSecret);

    try {
      const response = await fetch(`${host}/balance`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey,
          'api-secret': apiSecret,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch balance');
      }

      const result = await response.json();

      if(typeof(result)==='string' &&result.includes("no info returned from balance")){
        setBalance(JSON.stringify("wrong or missing api key/secret"));
      }else{

      const balances = result.list.map(item => ({
        totalWalletBalance: item.totalWalletBalance,
        walletBalances: item.coin.map(coin => ({
          coin: coin.coin,
          walletBalance: coin.walletBalance
        }))
      }));

      setBalance(JSON.stringify(balances));
    }
      
      

      // setBalance(JSON.stringify(result.list)); //! total results 
      setError(null);
    } catch (error) {
      setError(error.message);
      setBalance(null);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${host}/data`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setWebhooks(result);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [apiKey]);


  useEffect(() => {
    socket.on('webhook_received', (data) => {
      setWebhooks((prev) => [...prev, data]);
    });

    return () => {
      socket.off('webhook_received');
    };
  }, []);

  // socket.on('connect', () => {
  //   console.log('Connected to server');
  // });
  
  // socket.on('disconnect', () => {
  //   console.log('Disconnected from server, attempting to reconnect...');
  //   socket.connect();  // Automatically tries to reconnect
  // });


  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', position: 'relative' }}>
      <nav style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000
      }}>
        <div style={{
          padding: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isAuthenticated && <h3 style={{ margin: '0 15px 0 0' }}>Welcome, {user.name}</h3>}
            {isAuthenticated && (
              <button onClick={logout}
                style={{
                  padding: '5px 10px',
                  backgroundColor: "red",
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  marginRight: '10px'
                }}
              >Log Out</button>
            )}
          </div>
          <div>
            {!isAuthenticated ? (
              <button onClick={loginWithRedirect}
                style={{
                  padding: '5px 10px',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}>Log In</button>
            ) : (
              <>
                <label style={{ marginRight: '10px' }}>
                  API Key:
                  <input
                    type="text"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    style={{ marginLeft: '10px', padding: '5px', width: '200px' }}
                  />
                </label>
                <label style={{ marginRight: '10px' }}>
                  API Secret:
                  <input
                    type="password"
                    value={apiSecret}
                    onChange={(e) => setApiSecret(e.target.value)}
                    style={{ marginLeft: '10px', padding: '5px', width: '200px' }}
                  />
                </label>
                <button
                  onClick={handleConnect}
                  style={{
                    padding: '5px 10px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}>
                  Connect
                </button>
              </>
            )}
          </div>
        </div>
      </nav>

      {isAuthenticated && (
        <div style={{ marginTop: '80px' }}>
          {balance !== null && (
            <div style={{
              marginBottom: '20px',
              color: '#4CAF50',
              border: '1px solid #4CAF50',
              borderRadius: '5px',
              backgroundColor: '#f9f9f9',
              fontWeight: 'bold',
              textAlign: 'center',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              width: '800px',
              wordWrap: 'break-word',
              overflow: 'hidden',
              whiteSpace: 'normal'
            }}>
              <label>Balance: {balance}</label>
            </div>
          )}

          {error && <div style={{ color: 'red' }}>{error}</div>}

          <button
            onClick={() => setShowWebhooks(!showWebhooks)}
            style={{
              marginBottom: '20px',
              padding: '5px 10px',
              backgroundColor: '#007BFF',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}>
            {showWebhooks ? 'Show Open Positions' : 'Show Webhooks'}
          </button>

          {showWebhooks ? (
            <WebhookTable 
            webhooks={webhooks} 
            getData={fetchData}
            apiKey={apiKey}
            apiSecret={apiSecret} 
            />
          ) : (
            <OpenPositionsTable 
            apiKey={apiKey}
            apiSecret={apiSecret}
            />
          )}
          
          <ManualTrading 
          apiKey={apiKey}
          apiSecret={apiSecret}
          />
        </div>
      )}
    </div>
  );
}

export default App;
