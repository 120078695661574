import React, { useState } from 'react';
import host from "./constants";

const columns = ['time', 'symbol', "side", 'alert_price', 'message'];

const WebhookTable = ({ webhooks,getData,apiKey,apiSecret }) => {

  // State to track orders placed for each webhook
  const [ordersPlaced, setOrdersPlaced] = useState({});

  const handleSubmit = async (webhook) => {
    const orderData = {
        category: "linear", // only derivatives here right now
        orderType: "Market",
        side: webhook.side.includes("Buy") ? "Buy" : webhook.side.includes("Sell") ? "Sell" : null,
        qty: 10, //! change
        symbol: webhook.symbol,
        orderFilter: "Order",
    };

    const id = webhook.ulid; // Capture the ID immediately
    console.log(`id: ${webhook.ulid} >> Order Data sending from webhook signal:`, orderData);

    if (!id) {
        console.error('Webhook ID is undefined. Cannot proceed with order submission.');
        return; // Exit early if the ID is undefined
    }

    try {
        // Place the order
        const response = await fetch(`${host}/order`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'api-key': apiKey,
              'api-secret': apiSecret,
            },
            body: JSON.stringify(orderData),
        });

        const result = await response.json();
        console.log('Response:', result);

        if (result.res.retMsg === "OK") {
            alert(`Order went through, order_id ${result.res.result.orderId}`);

            // Update state to indicate that an order has been placed for this webhook
            setOrdersPlaced(prev => ({ ...prev, [webhook.time]: true })); // Assuming 'time' is unique for each webhook

            // Now update the order_sent status in the database
            await updateOrderSent(id);
        } else {
            alert(`Response:\n${result.res}`);
        }
    } catch (error) {
        alert('Error sending order:', error);
        console.error('Error sending order:', error);
    } finally {
        await getData();
    }
};

const updateOrderSent = async (ulid) => {
    try {
        console.log(`About to update webhook ID: ${ulid}`);
        const response = await fetch(`${host}/update_order_sent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ webhook_id: ulid }), // Send the webhook ID
        });

        const result = await response.json();
        console.log('Update Order Sent Response:', result);

        if (result.message) {
            console.log('Order sent status updated successfully!');
        } else {
            alert(`Failed to update order sent status:\n${result.error}`);
        }
    } catch (error) {
        alert('Error updating order sent status:', error);
        console.error('Error updating order sent status:', error);
    }
};



  return (
    <div style={{
      maxWidth: "1300px",
      maxHeight: '700px',
      overflowY: 'scroll',
      border: '1px solid #ddd',
      borderRadius: '5px',
      marginTop: '10px'
    }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
        <thead style={{ position: "sticky", top: "0px" }}>
          <tr style={{ backgroundColor: '#f9f9f9' }}>
            {columns.map((column, index) => (
              <th key={index} style={{ border: '1px solid #ddd', padding: '8px', textTransform: 'capitalize' }}>
                {column.replace('_', ' ')}
              </th>
            ))}
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {webhooks.map((webhook, index) => (

            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#fff' }}>
              {columns.map((column, colIndex) => (
                <td key={colIndex} style={{ border: '1px solid #ddd', padding: '8px' }}>
                  {webhook[column]}
                </td>
              ))}
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                {ordersPlaced[webhook.time] ? ( // Check if an order was placed for this webhook
                  <span style={{ color: 'green' }}>Order Used</span>
                ) : (
                  <button
                    onClick={() => handleSubmit(webhook)}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: '#007BFF',
                      color: 'white',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer'
                    }}>
                    Place Order @MKT
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WebhookTable;
