import React, { useEffect, useState } from 'react';
import host from "./constants"

// All columns
// const columns = ['symbol', 'size', 'avgPrice', 'side', 'unrealisedPnl', 'cumRealisedPnl', 'leverage', 'liqPrice', 'riskLimitValue', 'takeProfit', 'positionValue', 'tpslMode', 'isReduceOnly', 'riskId', 'markPrice', 'createdTime', 'positionIdx', 'seq', 'updatedTime', 'bustPrice', 'positionBalance', 'curRealisedPnl', 'positionStatus', 'stopLoss', 'tradeMode', "sessionAvgPrice"];

const columns = ['symbol', 'size', 'avgPrice', 'side', 'unrealisedPnl', 'cumRealisedPnl', 'leverage', 'liqPrice',  'takeProfit', 'positionValue', 'tpslMode', 'riskId', 'markPrice', 'createdTime', 'seq', 'updatedTime', 'positionBalance', 'curRealisedPnl', 'positionStatus', 'stopLoss', 'tradeMode'];

const OpenPositionsTable = ({apiKey,apiSecret}) => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await fetch(`${host}/get_positions`,{
          headers: {
            'Content-Type': 'application/json',
            'api-key': apiKey,
            'api-secret': apiSecret,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(`Response from positions: ${data.result.list}`);
        setPositions(data.result.list);
      } catch (error) {
        console.error('Error fetching positions:', error);
      }
    };

    // Fetch positions immediately
    fetchPositions();

    // Set up interval to fetch positions every second
    const intervalId = setInterval(fetchPositions, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleClosePosition = async (position) => {
    try {
      const response = await fetch(`${host}/order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey,
          'api-secret': apiSecret,
        },
        body: JSON.stringify({
          symbol: position.symbol,
          qty: position.size,
          side: position.side === "Sell" ? "Buy" : "Sell", // Toggle between Buy and Sell
          orderType: "Market",
          category: "linear"
        }),
      });

      console.log(`sending mkt sell for ${position.symbol} ${position.size}qty`);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Position closed:', result);
      alert(`Response from close position: ${result.res}`);
      
    } catch (error) {
      console.error('Error closing position:', error);
    }
  };

  return (
    <div style={{
      maxWidth: "1300px",
      maxHeight: '700px',
      overflowY: 'scroll',
      border: '1px solid #ddd',
      borderRadius: '5px',
      marginTop: '10px'
    }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
        <thead style={{ position: "sticky", top: "0px" }}>
          <tr style={{ backgroundColor: '#f9f9f9' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Actions</th>
            {columns.map((col, index) => (
              <th key={index} style={{ border: '1px solid #ddd', padding: '8px' }}>
                {col.charAt(0).toUpperCase() + col.slice(1)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {positions.length > 0 ? positions.map((position, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#fff' }}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                <button
                  onClick={() => handleClosePosition(position)}
                  style={{
                    padding: '5px 10px',
                    backgroundColor: '#FF5733',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}>
                  Close Position
                </button>
              </td>
              {columns.map((col, idx) => (
                <td key={idx} style={{ border: '1px solid #ddd', padding: '8px', 
                  color: col === 'unrealisedPnl' ? (position[col] > 0 ? 'green' : 'red') : 'inherit' }}>
                  {position[col]}
                </td>
              ))}
            </tr>
          )) : null}
        </tbody>
      </table>
    </div>
  );
};

export default OpenPositionsTable;
